import { CrmEndpoint, CrmEndpointDecorator } from 'common-module/endpoint';
import { map, tap, catchError } from 'rxjs/operators';
import * as i0 from '@angular/core';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { of, NEVER } from 'rxjs';
import { __decorate } from 'tslib';
const crmGetUserName = (user, options = {
  title: true
}) => {
  const {
    title
  } = options;
  if (!user) {
    return '-';
  }
  let name = user.firstName ?? '';
  if (user.lastName) {
    name += ` ${user.lastName}`;
  }
  if (!title || !user.title) {
    const trimmed = name.trim();
    return trimmed.length ? trimmed : user.email ?? '';
  }
  if (typeof user.title === 'string') {
    return `${user.title} ${name.trim()}`;
  } else {
    if (user.title.before) {
      name = `${user.title.before} ${name}`;
    }
    if (user.title.after) {
      name = `${name}, ${user.title.after}`;
    }
  }
  return name.replace(/\s+/g, ' ');
};
class CrmUserEndpoint extends CrmEndpoint {
  login(body, options) {
    return this.request('POST', 'login', {
      ...options,
      body
    }).pipe(map(this.responseTransformer.transform));
  }
  logout(options) {
    return this.request('POST', 'logout', options);
  }
  recovery(body, options) {
    return this.request('POST', 'recovery', {
      ...options,
      body
    });
  }
  profile(options) {
    return this.request('GET', 'profile', options).pipe(map(this.responseTransformer.transform));
  }
  updateProfile(body, options) {
    return this.request('POST', 'profile', {
      ...options,
      body
    }).pipe(map(this.responseTransformer.transform));
  }
}
class CrmUserService {
  /**
   * Get logged user profile with observable complete
   */
  get user$() {
    if (this.loggedUser) {
      return of(this.loggedUser);
    }
    return this.getProfile();
  }
  createUser(body) {
    return this.endpoint.create(body);
  }
  getUser(id) {
    return this.endpoint.read(id);
  }
  updateUser(id, body, options) {
    return this.endpoint.update(id, body, options).pipe(tap(user => {
      if (this.loggedUser?._id === user._id) {
        this.loggedUser = user;
      }
    }));
  }
  listUsers(params) {
    return this.endpoint.list({
      params
    });
  }
  listAllUsers(params) {
    return this.endpoint.listAll({
      params
    });
  }
  login(body, options) {
    return this.endpoint.login(body, options).pipe(tap(user => {
      this.loggedUser = user;
    }));
  }
  logout(options) {
    return this.endpoint.logout({
      responseType: 'text',
      ...options
    }).pipe(tap(() => {
      delete this.loggedUser;
    }));
  }
  getProfile(options) {
    return this.endpoint.profile(options).pipe(tap(profile => {
      this.loggedUser = profile;
    }));
  }
  updateProfile(body, options) {
    return this.endpoint.updateProfile(body, options);
  }
  resetPassword(body) {
    return this.endpoint.recovery(body, {
      responseType: 'text'
    });
  }
  static {
    this.ɵfac = function CrmUserService_Factory(t) {
      return new (t || CrmUserService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmUserService,
      factory: CrmUserService.ɵfac,
      providedIn: 'root'
    });
  }
}
__decorate([CrmEndpointDecorator({
  configName: 'crm',
  endpointName: 'users',
  endpoint: CrmUserEndpoint
})], CrmUserService.prototype, "endpoint", void 0);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmUserService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, {
    endpoint: []
  });
})();
class CrmUserResolver {
  constructor() {
    this.userService = inject(CrmUserService);
    this.router = inject(Router);
  }
  resolve(route) {
    const {
      data = {},
      params
    } = route;
    return this.userService.getUser(params[data['routeParam'] ?? 'id']).pipe(catchError(() => {
      this.router.navigate([data['redirectPath'] ?? '/users']);
      return NEVER;
    }));
  }
  static {
    this.ɵfac = function CrmUserResolver_Factory(t) {
      return new (t || CrmUserResolver)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CrmUserResolver,
      factory: CrmUserResolver.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CrmUserResolver, [{
    type: Injectable
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CrmUserEndpoint, CrmUserResolver, CrmUserService, crmGetUserName };
